<template>
  <div class="home">
    <div class="appContainer">
      <div class="home-elementor">
        <h1>Если меню красиво оформлено, у посетителя проснется аппетит уже во время выбора закусок и салатов.</h1>
      </div>
    </div>
    <Cases :cases="cases"></Cases>
  </div>
</template>

<script lang="ts">
import { ComputedRef, defineComponent } from 'vue';
import Cases from '@/components/Cases.vue';
import Case from '@/types/Case';
import { computed } from "vue";
import { useStore } from "vuex";
// eslint-disable-next-line @typescript-eslint/no-var-requires
// var _ = require('lodash');

export default defineComponent({
  name: 'Home',
  components: {
    Cases
  },
  setup() {
    const store = useStore();
    const cases: ComputedRef<Array<Case>> = computed(() => store.state.cases)
    // cases = _.shuffle(cases.value)

    return {
      cases
    }
  }
});
</script>

<style lang="scss" scoped>
.home {
  &-elementor {
    padding: 6rem 0;
    width: 70vw;
    text-align: left;
    @include get-media($mobile) {
        width: 90vw;
        padding: 0;
    }
    h1 {  
      font-weight: 400;
      font-size: 75px;
      line-height: 82px;
      font-family: 'Roboto Condensed', sans-serif;
      @include get-media($mobile) {
          font-size: 42px;
          line-height: 56px;
      }
    }
  }
}
</style>
