<template>
    <div class="cases-holder">
        <router-link v-for="item in cases" :key="item.id" class="case-block" :to="{ name: 'Case', params: { id: item.id }}" >
            <div class="case-image" :style="{ backgroundImage: `url(${item.imageUrl})` }"></div>
            <div class="case-name">{{ item.name }}</div>
        </router-link>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import Case from '@/types/Case'

export default defineComponent({
    props: {
        cases: {
            required: true,
            type: Array as PropType<Array<Case>>
        }
    }
})
</script>

<style lang="scss" scoped>
.cases {
    &-holder {
        display: flex;
        flex-wrap: wrap;
        @include get-media($mobile) {
            flex-direction: column;
        }
        .case {
            &-block {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                width: calc(100% / 3 - 2px);
                height: calc(100% / 3 - 2px);
                border: 1px solid white;
                overflow: hidden;
                @include get-media($mobile) {
                    width: 100%;
                }
                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #000000;
                    opacity: 0;
                    transition: opacity 300ms ease-in-out;
                    z-index: 10;
                }
                &:hover {
                    & > .case-image {
                        transform: scale(1.05);
                    }
                    & > .case-name {
                        opacity: 1;
                    }
                    &::after {
                        opacity: 0.5;
                    }
                }
            }
            &-image {
                min-height:100%;
                width: 100%;
                padding-top: 100%;
                background-size: cover;
                background-position: center center;
                transition: all 500ms ease-in-out;
            }
            &-name {
                position: absolute;
                z-index: 20;
                font-size: 48px;
                color: white;
                opacity: 0;
                transition: opacity 300ms ease-in-out;
                font-family: 'Bebas Neue';
                pointer-events: none;
            }
        }
    }
}
</style>
