
import { ComputedRef, defineComponent } from 'vue';
import Cases from '@/components/Cases.vue';
import Case from '@/types/Case';
import { computed } from "vue";
import { useStore } from "vuex";
// eslint-disable-next-line @typescript-eslint/no-var-requires
// var _ = require('lodash');

export default defineComponent({
  name: 'Home',
  components: {
    Cases
  },
  setup() {
    const store = useStore();
    const cases: ComputedRef<Array<Case>> = computed(() => store.state.cases)
    // cases = _.shuffle(cases.value)

    return {
      cases
    }
  }
});
