<template>
  <header>
    <Header></Header>
  </header>
  <main>
    <router-view/>
  </main>
  <Footer></Footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default defineComponent({
  components: {
    Header,
    Footer
  }
})
</script>


<style lang="scss">
body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-family: "Roboto Condensed", sans-serif
}
ul {
  list-style-type: none;
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

.appContainer {
  width: 92vw;
  margin: 0 auto 0 
}

.btn {
  background-color: $main-color;
  padding: 1.33rem 6rem;
  border-radius: 2rem;
  line-height: 12px;
  box-shadow: 0 4px 33px 0 rgba(255,90,0,0.5);
  color: white;
  font-size: 133%;
  cursor: pointer;
  display: inline-block;
}

button {
  border: none;
  background: none;
}

h1 {
  text-align: left;
}
</style>
