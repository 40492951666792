import { createStore } from 'vuex'

export default createStore({
  state: {
    cases: [{
        id: "1",
        name: "VZлетный",
        imageUrl: require("@/assets/images/vzletniy.jpg")
      },
      {
        id: "2",
        name: "O'Petit",
        imageUrl: require("@/assets/images/opetit.jpg")
      },
      {
        id: "3",
        name: "Jent",
        imageUrl: require("@/assets/images/jent.jpg")
      },
      {
        id: "4",
        name: "Шашлычный двор",
        imageUrl: require("@/assets/images/doka_pizza.jpg")
      }
      ,
      {
        id: "5",
        name: "Gastroli",
        imageUrl: require("@/assets/images/gastroli.jpg")
      },
      {
        id: "6",
        name: "Мясная лавка",
        imageUrl: require("@/assets/images/meat_lavka.jpg")
      },
      {
        id: "7",
        name: "VinoGrad",
        imageUrl: require("@/assets/images/vinograd.jpg")
      },
      {
        id: "8",
        name: "O'Petit",
        imageUrl: require("@/assets/images/coffee_time.jpg")
      },
      {
        id: "9",
        name: "The Ranch",
        imageUrl: require("@/assets/images/theranch.jpg")
      }
    ]
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
  getters: {
    getCaseById: ( ({ cases })  => (id: string) =>  {
      return cases.find(item => item.id == id)
    })
  }
})
