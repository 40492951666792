
import { defineComponent, PropType } from 'vue'
import Case from '@/types/Case'

export default defineComponent({
    props: {
        cases: {
            required: true,
            type: Array as PropType<Array<Case>>
        }
    }
})
