
import { defineComponent } from 'vue'
// import gsap from "gsap";

export default defineComponent({
  setup() {
    const onHamburgerClick = (e: Event) => {
      console.log(e.target);
      // gsap.to("#app", { x: '-40%', duration: 0.5 });
    }
    return {
      onHamburgerClick
    }
  },
})
